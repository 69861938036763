<template>
  <div style="height: 500px">
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col :style="result['cardWidth']">
          <v-card>
            <div
              class="pt-7 d-flex flex-column justify-space-between align-center"
            >
              <v-img max-width="200" src="img/dl.png"></v-img>
            </div>
            <v-card-title> {{ result.title }}</v-card-title>

            <v-card-text>
              {{ result.message }}
            </v-card-text>
            <v-card-actions>
              <div class="mr-2 d-inline-block">
                <router-link to="?f=global&f2=resetPasswordRequest">
                  <primaryButton
                    label="Retry"
                    type="button"
                    color="save"
                    id="retry_Button"
                    data-type="retry_Button"
                    icon="mdi-restart"
                  ></primaryButton>
                </router-link>
                  <router-link to="?f=global&f2=login">
                      <primaryButton
                              label="Close"
                              type="button"
                              color="secondary"
                              id="close_Button"
                              data-type="retry_Button"
                              icon="mdi-close-circle"
                              class="ml-3"
                      ></primaryButton>
                  </router-link>
              </div>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
export default {
  components: { primaryButton },

  props: ["result"],
  data: function () {
    return {};
  },
};
</script>